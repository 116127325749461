<template>
<div>

  <v-row class="mt-2">
    <v-col cols="4">
      <v-autocomplete outlined dense hide-details class="mt-3" item-text="name" item-value="id"
                      :items="products" label="Select Product" v-model="selectedProductId"/>
    </v-col>
    <v-col cols="4" style="margin-top: 14px">
      <v-btn :disabled="!selectedProductId" @click="getPriceHistory" class="primary">Show Price History</v-btn>
    </v-col>
  </v-row>

  <v-data-table :headers="headers" :items="priceHistory" class="elevation-0 mt-4"
                :footer-props="{itemsPerPageOptions:rowsPerPageItems}" :loading="priceHistoryLoading">
    <template v-slot:no-data>No Product Price History Available</template>
    <template v-slot:no-results>No Product Price History Available</template>
    <template v-slot:[`item.serialNo`]="{ index }">{{(index+1)}}</template>
    <template v-slot:[`item.oldPrice`]="{ item }">₹ {{item.oldPrice}}</template>
    <template v-slot:[`item.newPrice`]="{ item }">₹ {{item.newPrice}}</template>
    <template v-slot:[`item.updatedOn`]="{ item }">{{ moment.unix(item.updatedOn).format("DD-MMM-YYYY hh:mm A")}}</template>
  </v-data-table>

</div>
</template>

<script>
import { Constants } from '@/Constants'
import moment from 'moment'

export default {
  name: 'PriceHistory',
  data () {
    return {
      moment,
      priceHistory: [],
      priceHistoryLoading: false,
      selectedProductId: null,
      headers: [
        { text: 'Sr No', value: 'serialNo' },
        { text: 'Old Price', value: 'oldPrice' },
        { text: 'New Price', value: 'newPrice' },
        { text: 'Updated On', value: 'updatedOn' }
      ],
      rowsPerPageItems: [25, 50, 100, -1]
    }
  },
  computed: {
    categories () { return this.$store.state.productCategories },
    products () {
      const arrays = this.categories.map(c => c.childCategory.map(p => ({ id: p.productId, name: p.productName })))
      return [].concat.apply([], arrays)
    }
  },
  methods: {
    getPriceHistory () {
      this.priceHistoryLoading = true
      const data = { ...Constants.getPriceHistory, parameters: [localStorage.session, this.selectedProductId] }
      // eslint-disable-next-line no-undef
      axios.post(Constants.api_url, data).then(res => {
        if (res.data.flag) {
          this.priceHistory = res.data.data || []
        }
        this.priceHistoryLoading = false
      }).catch(e => console.log('getPriceHistory', e))
    }
  }
}
</script>

<style scoped>

</style>
