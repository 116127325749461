<template>
  <div id="Login">
    <loader v-if="loading"></loader>
    <v-container fill-height grid-list-md text-xs-center>
      <v-layout row wrap>
        <v-flex xs12 sm10 md6 lg4>
          <v-card ref="form" style="padding: 40px 30px !important;">
            <h1 class="headline" style="font-size: 30px !important; padding-bottom: 30px; color: #008abc">MeraCRM</h1>
            <v-card-text style="padding: 0">
              <v-text-field ref="username" v-model="username" :rules="[rules.mobileRequired, rules.userNameLen]"
                            counter maxlength="10" label="Mobile No." required prepend-icon="mdi-phone"></v-text-field>
              <v-text-field ref="password" v-model="password" required prepend-icon="mdi-lock"
                            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            :rules="[rules.passwordRequired]" :type="showPassword ? 'text' : 'password'"
                            label="Password" @click:append="showPassword = !showPassword"
              ></v-text-field>
              <v-text-field ref="domain" v-model="domain" :rules="[rules.domainRequired]"
                            label="Domain" required prepend-icon="mdi-domain"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="blue" text @click="validate">Submit</v-btn>
              <v-btn class="red" text @click="resetForm">Reset</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <Snackbar :toaster.sync="toaster"></Snackbar>
  </div>
</template>

<script>
import md5 from 'js-md5'
import Loader from '@/components/Loader'

import { Constants } from '@/Constants'
import Snackbar from '@/components/Snackbar'

function checkResponse (data) {
  if (data.error) {
    console.error('Error: ', data.error)
    return { flag: false, errorMessage: data.error.error }
  } else {
    return { flag: true }
  }
}

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  components: { Snackbar, Loader },
  // mixins: [getProducts],
  mounted () {
    const self = this
    window.addEventListener('keypress', function (e) {
      if (e.keyCode === 13) { self.validate() }
    })
  },

  data () {
    return {
      loading: false,
      toaster: { enable: false, color: 'green', message: '' },
      username: '',
      password: '',
      domain: '',
      showPassword: false,
      errorMessage: '',
      rules: {
        mobileRequired: value => !!value || 'Mobile Required.',
        domainRequired: value => !!value || 'Domain Required.',
        passwordRequired: value => !!value || 'Password Required.',
        userNameLen: v => v ? v.length === 10 : 'Must be 10 characters'
      },
      formHasErrors: false
    }
  },
  computed: {
    form () {
      return {
        username: this.username,
        password: this.password,
        domain: this.domain
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('session') !== null) {
      next({ path: '/Dashboard' })
    } else {
      next()
    }
  },
  created () {
    if (localStorage.getItem('session') !== null) {
      this.$router.push('/Dashboard')
    }
  },
  methods: {
    validate () {
      this.loading = true
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f].validate(true)
      })
      if (!this.formHasErrors) { this.doLogin() } else this.loading = false
    },
    doLogin () {
      const self = this
      this.loading = true
      const request = {
        methodName: 'loginUser',
        parameters: ['MOBILE', 'hashedpasswordMD5', 'DOMAIN', 1],
        serviceName: 'com.avinashi.meraCRM.wrapper.UserServiceWrapper'
      }
      request.parameters[0] = this.username
      request.parameters[1] = md5(this.password)
      request.parameters[2] = this.domain
      // eslint-disable-next-line no-undef
      axios.post(Constants.api_url, request).then(response => {
        const r = checkResponse(response.data)
        if (r.flag) {
          localStorage.setItem('user', JSON.stringify({ mobile: self.username, domain: self.domain }))
          localStorage.setItem('session', response.data.data.sessionId)
          self.loading = false
          // self.getProducts()
          self.$router.push('/Dashboard')
          self.$router.go()
        } else {
          self.loading = false
          self.errorMessage = r.errorMessage
          self.toaster = { enable: true, color: 'red', message: r.errorMessage }
        }
      }).catch(error => {
        console.log(error)
        self.loading = false
        self.toaster = { enable: true, color: 'red', message: error }
      })
    },
    resetForm () {
      this.errorMessage = ''
      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })
    }
  }
}
</script>

<style scoped>

</style>
