import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState({ key: 'meracrm-pricelist' })],
  state: {
    productCategories: []
  },
  mutations: {
    setProductCategories (state, payload) {
      state.productCategories = payload
    },
    clearStore (state) {
      state.productCategories = []
    }
  },
  getters: {},
  actions: {},
  modules: {}
})
export default store
