<template>
    <div id="app">
      <v-app v-bind:app="loggedIn" clipped-left>
      <v-toolbar v-if="loggedIn" dark absolute min-width="100%">
        <v-layout>
            <v-img max-width="140" src="meracrmlogo.png">
              <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-layout>
            </v-img>
            <div style="font-size: 20px" class="m-l-10"> | PriceList</div>
        </v-layout>
        <div class="row" style="position:absolute; right: 40px">
          <div>
            <v-flex xs6> {{user.domain}}</v-flex>
            <v-flex xs6> {{user.mobile}}</v-flex>
          </div>
          <div >
            <v-btn @click="logout" icon class="ml-2">
              <v-icon style="color: red">mdi-logout</v-icon>
            </v-btn>
          </div>
        </div>
      </v-toolbar>
      <v-main>
        <v-container fluid>
          <router-view :key="$route.fullPath" class="mt-16"></router-view>
        </v-container>
      </v-main>
  </v-app>
    </div>
</template>

<script>

import getProducts from '@/mixins/getProducts'

export default {
  name: 'App',
  mixins: [getProducts],
  data: () => ({
    loggedIn: false,
    user: null
  }),
  created () {
    if (localStorage.getItem('session') === null) {
      this.loggedIn = false
      this.$router.push('/Login')
    } else {
      this.loggedIn = true
      this.user = JSON.parse(localStorage.getItem('user'))
      this.getProducts()
    }
  },
  methods: {
    logout () {
      localStorage.clear()
      this.$store.commit('clearStore')
      this.$router.push('/Login')
      this.$router.go()
    }
  }
}
</script>
<style>
.v-dialog {
  box-shadow: 0 0 0 0 !important;
  -webkit-box-shadow: 0 0 0 0 !important;
}
</style>
