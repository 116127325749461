<template>
  <div id="Loader">
    <v-layout row justify-center>
      <v-dialog v-model="loading" persistent content content-class="centered-dialog">
        <v-container fill-height>
          <v-layout column justify-center align-center>
            <v-progress-circular indeterminate :size="50" :width="3" color="blue"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Loader',
  data () {
    return { loading: true }
  }
}
</script>

<style scoped>
  .dialog.centered-dialog, .v-dialog.centered-dialog {
    background: rgba(118, 163, 208, 0.68);
    box-shadow: none;
    border-radius: 3px;
    width: auto;
    color: whitesmoke;
  }
</style>
