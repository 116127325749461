<template>
<div>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <div class="mb-8 ml-4 fs-18"> {{priceList.length}} PRICE LIST
            <v-btn class="float-right primary" @click="priceListForm=true">Add Price List</v-btn>
          </div>
          <div v-if="priceList.length" class="price-list-border">
              <v-list dense>
                <template v-for="(pl, pIndex) in priceList">
                  <v-list-item :key="'p-'+pIndex">
                    <v-row>
                      <v-col cols="4" class="pt-5">{{pl.priceListName}}</v-col>
                      <v-col cols="4" class="text-center pt-5">{{pl.productIds.length}} Products</v-col>
                      <v-col cols="4" class="text-right">
                        <v-btn icon title="Edit" @click="onEditPriceList(pl)"><v-icon size="20">mdi-pencil</v-icon></v-btn>
                        <v-btn icon title="Download" @click="onDownloadPriceList(pl)"><v-icon size="20">mdi-download</v-icon></v-btn>
                        <v-btn icon title="Delete" @click="onDeletePriceList(pl)" :loading="pl.deleteLoading">
                          <v-icon size="20" class="red--text text--darken-1">mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-divider v-if="pIndex < priceList.length - 1" :key="'divider-'+pIndex"/>
                </template>
              </v-list>
          </div>
          <div v-else class="no-content-border">No Price List Added</div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <div class="fs-18 pb-4"> UPDATE PRICE
            <v-btn class="float-right primary" @click="updatePriceForm=true">Update price</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <div class="fs-18"> PRODUCT PRICE HISTORY</div>
          <PriceHistory></PriceHistory>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

  <PriceListForm :dialog.sync="priceListForm" :id.sync="priceListId" v-if="priceListForm"
                 ref="price-list-form" @fetchPriceList="getPriceList"/>
  <DownloadPriceList :dialog.sync="downloadPriceList" ref="download-price-list"/>
  <UpdatePriceForm :dialog.sync="updatePriceForm" v-if="updatePriceForm"/>
  <ConfirmationDialog :confirmationMessage="confirm.message" @confirm="confirm.method" @close="confirm.closeMethod"
                      v-if="confirm.enable"/>
  <Snackbar :toaster.sync="toaster"></Snackbar>
</div>
</template>

<script>
import { Constants } from '@/Constants'
import PriceListForm from '@/components/PriceListForm'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import Vue from 'vue'
import Snackbar from '@/components/Snackbar'
import DownloadPriceList from '@/components/DownloadPriceList'
import UpdatePriceForm from '@/components/UpdatePriceForm'
import PriceHistory from '@/components/PriceHistory'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Dashboard',
  components: { PriceHistory, UpdatePriceForm, DownloadPriceList, Snackbar, ConfirmationDialog, PriceListForm },
  data () {
    return {
      Constants,
      priceList: [],
      priceListForm: false,
      priceListId: null,
      downloadPriceList: false,
      updatePriceForm: false,
      confirm: { enable: false },
      toaster: { enable: false }
    }
  },
  methods: {
    async onEditPriceList (row) {
      this.priceListForm = true
      await Vue.nextTick()
      this.priceListId = row.priceListId
      this.$refs['price-list-form'].setPriceList(row)
    },
    onDownloadPriceList (row) {
      this.downloadPriceList = true
      this.$refs['download-price-list'].setPriceList(row)
    },
    onDeletePriceList (row) {
      this.confirm = { enable: true, message: 'Are you sure want to delete Price List?', row, method: this.deletePriceList, closeMethod: this.closeConfirmPopup }
    },
    closeConfirmPopup () {
      this.confirm = { enable: false }
    },
    deletePriceList () {
      Vue.set(this.confirm.row, 'deleteLoading', true)
      this.confirm.enable = false
      const data = { ...Constants.removePriceList, parameters: [localStorage.session, this.confirm.row.priceListId] }
      // eslint-disable-next-line no-undef
      axios.post(Constants.api_url, data).then(res => {
        if (res.data.flag) {
          this.toaster = { enable: true, color: 'green', message: 'Price List deleted successfully' }
          this.getPriceList()
        }
        Vue.set(this.confirm.row, 'deleteLoading', false)
        this.closeConfirmPopup()
      }).catch(e => console.log('deletePriceList', e))
    },
    getPriceList () {
      const data = { ...Constants.getPriceList, parameters: [localStorage.session] }
      // eslint-disable-next-line no-undef
      axios.post(Constants.api_url, data).then(res => {
        if (res.data.flag) {
          this.priceList = res.data.data
        }
      }).catch(e => console.log('getPriceList', e))
    }
  },
  created () {
    this.getPriceList()
  }
}
</script>

<style scoped>
.fs-18{
  font-size: 18px;
}
.no-content-border{
  padding: 40px;
  border: 1px solid #dedede;
  text-align: center
}
.price-list-border{
  border: 1px solid #dedede;
}
</style>
