<template>
  <div>

    <v-dialog v-model="modal" persistent>
      <v-card>
        <v-card-title class="text-h5 pl-6 font-weight-regular">Update Price
          <v-btn class="close-btn" icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pb-0 mt-4">
          <v-form ref="form">
            <v-row>
              <v-col cols="6">
               <!-- <v-text-field v-model="form.priceListName" :rules="[v => !!v || 'Please enter Price List Name']"
                              label="Price List Name"/> -->
                <label>Pricing Detail</label>
                <v-radio-group v-model="form.operation" row>
                  <v-radio label="Add Price" :value="priceOperation.add"/>
                  <v-radio label="Subtract Price" :value="priceOperation.subtract"/>
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                <v-radio-group v-model="form.type" column label="Amount">
                  <v-radio :value="priceType.percentage" class="h-50">
                    <template v-slot:label>
                      By Percentage
                      <v-text-field type="number" :disabled="form.type!==priceType.percentage" outlined class="ml-8 mt-4 value-field"
                                    dense v-model="form.percentValue" :rules="[v => (form.type!==priceType.percentage) || v>0 || 'Invalid Percentage']"/>
                    </template>
                  </v-radio>
                  <v-radio :value="priceType.amount" class="h-50">
                    <template v-slot:label>
                      Fix Amount
                      <v-text-field type="number" :disabled="form.type!==priceType.amount" outlined class="ml-14 mt-4 value-field"
                                  dense v-model="form.amountValue" :rules="[v => (form.type!==priceType.amount) || v>0 || 'Invalid Amount']"/>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12"> <div style="font-size: 16px">Select Products</div>
                <div class="float-right mb-2 mr-2 select-all"><input type="checkbox" v-model="isSelectedAll" @change="onSelectAll"/>
                  <span class="ml-2">Select All </span></div>
                <v-expansion-panels focusable>
                  <v-expansion-panel v-for="(cat, cIndex) in categories" :key="'c-'+cIndex">
                    <v-expansion-panel-header>
                      <div :key="'container-'+cIndex">
                        <input type="checkbox" v-model="categoryObj[cat.categoryId]"
                               :id="'id-'+cIndex" @click.stop="onSelectCategory(cat)"/>
                        <label :for="'id-'+cIndex" class="ml-2" @click.stop>{{cat.categoryName}}</label>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <template v-for="(p, pIndex) in cat.childCategory">
                        <div :key="'p-'+cIndex+'-'+pIndex" class="pa-2 p-row" v-if="!p.isCategory">
                          <input type="checkbox" v-model="productObj[p.productId]" :id="'id-'+cIndex+'-'+pIndex" @click.stop="onSelectProduct(cat, p.productId)"/>
                          <label :for="'id-'+cIndex+'-'+pIndex" class="ml-2" @click.stop>{{p.productName}}</label>
                        </div>
                      </template>
                     <!-- <v-list dense>
                        <template v-for="(p, pIndex) in cat.childCategory">
                          <v-list-item :key="'p-'+cIndex+'-'+pIndex">
                            <v-checkbox :label="p.productName" :value="p.productId" v-model="form.productIds" hide-details/>
                            <div style="position: absolute; right: 20px">₹ {{p.price}}</div> -->
                         <!-- </v-list-item>
                          <v-divider class="mt-2" v-if="pIndex < cat.childCategory.length - 1" :key="'divider-'+pIndex"/>
                        </template>
                      </v-list> -->
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize white elevation-0" text @click="closeDialog">Close</v-btn>
          <v-btn class="primary px-3 text-capitalize" :loading="formLoading" dark @click="handleSubmit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Snackbar :toaster.sync="toaster"></Snackbar>
  </div>
</template>

<script>
import Snackbar from '@/components/Snackbar'
import { Constants, priceOperation, priceType } from '@/Constants'
import getProducts from '@/mixins/getProducts'
import Vue from 'vue'
const form = { operation: priceOperation.add, type: priceType.percentage, percentValue: 0, amountValue: 0, productIds: [] }

export default {
  name: 'UpdatePriceForm',
  components: { Snackbar },
  props: ['dialog'],
  mixins: [getProducts],
  data () {
    return {
      priceOperation,
      priceType,
      form: { ...form },
      formLoading: false,
      toaster: { enable: false },
      productObj: {},
      categoryObj: {},
      isSelectedAll: false
    }
  },
  computed: {
    modal: {
      get () { return this.dialog },
      set (val) { this.$emit('update:dialog', val) }
    },
    categories () { return this.$store.state.productCategories }
  },
  methods: {
    onSelectCategory ({ categoryId, childCategory }) {
      const flag = !this.categoryObj[categoryId] // toggle
      Vue.set(this.categoryObj, categoryId, flag)
      childCategory.forEach(p => {
        if (!p.isCategory) Vue.set(this.productObj, p.productId, flag)
      })
      this.checkSelectAll()
    },
    onSelectProduct ({ categoryId, childCategory }, productId) {
      if (productId) Vue.set(this.productObj, productId, !this.productObj[productId]) // toggle
      const flag = childCategory.every(p => (this.productObj[p.productId] || p.isCategory))
      Vue.set(this.categoryObj, categoryId, flag)
      this.checkSelectAll()
    },
    onSelectAll () {
      this.categories.forEach(c => {
        Vue.set(this.categoryObj, c.categoryId, this.isSelectedAll)
        c.childCategory.forEach(p => {
          if (!p.isCategory) Vue.set(this.productObj, p.productId, this.isSelectedAll)
        })
      })
    },
    checkSelectAll () {
      this.isSelectedAll = this.categories.every(c => this.categoryObj[c.categoryId])
    },
    handleSubmit () {
      this.form.productIds = Object.keys(this.productObj).filter(key => (key && this.productObj[key]))
      if (this.$refs.form.validate() && this.form.productIds.length) this.updatePrice()
      else if (this.$refs.form.validate() && !this.form.productIds.length) {
        this.toaster = { enable: true, color: 'red', message: 'Please select at least one product' }
      }
    },
    async updatePrice () {
      this.formLoading = true
      const { operation, type, percentValue, amountValue, productIds } = this.form
      const value = type === priceType.percentage ? percentValue : amountValue
      const data = { ...Constants.updatePrice, parameters: [localStorage.session, Number(operation), Number(type), Number(value), productIds] }
      // eslint-disable-next-line no-undef
      axios.post(Constants.api_url, data).then(res => {
        if (res.data.flag) {
          this.toaster = { enable: true, color: 'green', message: 'Price updated successfully' }
          // this.getProducts()
          this.closeDialog()
        }
        this.formLoading = false
      }).catch(e => console.log('updatePrice', e))
    },
    closeDialog () {
      this.$refs.form.resetValidation()
      this.form = { ...form, productIds: [] }
      this.categoryObj = {}
      this.productObj = {}
      this.isSelectedAll = false
      // this.categories = []
      this.$emit('update:dialog', false)
    }
  }
}
</script>

<style scoped>
.close-btn{
  position: absolute;
  right: 20px;
}
.value-field{
  max-width: 100px;
}
.p-row{
  border-bottom: 1px solid #dedede;
}
.p-row label, .p-row input{
  cursor: pointer;
}
</style>
<style>
.h-50 > .v-label{
  height: 50px !important;
}
.select-all{
  font-size: 16px;
  color: black;
  font-weight: 600;
}
</style>
