<template>
  <div>

    <v-dialog v-model="modal" persistent scrollable>
      <v-card>
        <v-card-title class="text-h5 pl-6 font-weight-regular">
          <span v-if="showForm">{{id?'Edit':'Add'}} Price List</span>
          <span v-else>{{id?'Edit':'Add'}} Price List - Set Product Sequence</span>
          <v-btn class="close-btn" icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text v-if="showForm" class="pb-0 mt-4">
          <v-form ref="form">
            <v-row>
              <v-col cols="6" class="mt-2">
                <v-text-field v-model="form.priceListName" :rules="[v => !!v || 'Please enter Price List Name']"
                              label="Price List Name" outlined dense/>
              </v-col>
              <v-col cols="12"> <div style="font-size: 16px">Select Products</div>
                <div class="float-right mb-2 mr-2 select-all"><input type="checkbox" v-model="isSelectedAll" @change="onSelectAll"/>
                  <span class="ml-2">Select All </span></div>
                <v-expansion-panels focusable>
                  <v-expansion-panel v-for="(cat, cIndex) in categories" :key="'c-'+cIndex">
                    <v-expansion-panel-header>
                      <div :key="'container-'+cIndex">
                        <input type="checkbox" v-model="categoryObj[cat.categoryId]"
                               :id="'id-'+cIndex" @click.stop="onSelectCategory(cat)"/>
                        <label :for="'id-'+cIndex" class="ml-2" @click.stop>{{cat.categoryName}}</label>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <template v-for="(p, pIndex) in cat.childCategory">
                      <div :key="'p-'+cIndex+'-'+pIndex" class="pa-2 p-row" v-if="!p.isCategory">
                        <input type="checkbox" v-model="productObj[p.productId]" :id="'id-'+cIndex+'-'+pIndex" @click.stop="onSelectProduct(cat, p.productId)"/>
                        <label :for="'id-'+cIndex+'-'+pIndex" class="ml-2" @click.stop>{{p.productName}}</label>
                      </div>
                      </template>
                     <!-- <v-list dense>
                        <template v-for="(p, pIndex) in cat.childCategory">
                          <v-list-item :key="'p-'+cIndex+'-'+pIndex">
                            <v-checkbox :label="p.productName" :value="p.productId" v-model="form.productIds" hide-details/>
                          </v-list-item>
                          <v-divider class="mt-2" v-if="pIndex < cat.childCategory.length - 1" :key="'divider-'+pIndex"/>
                        </template>
                      </v-list> -->
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-text v-else class="pb-0 mt-4">
          <draggable @end="drag=false" @start="drag=true" group="people" v-model="selectedProducts" style="display: contents;" class="py-0">
            <div v-for="(item, index) in selectedProducts" :key="'p-'+index" class="product-row">
              <span class="px-4 my-0"><v-icon class="drag-icon">mdi-drag</v-icon></span>
              <span class="px-4 my-0">{{index+1}}</span>
              <span class="px-4 my-0">{{ item.name}}</span>
            </div>
          </draggable>
        </v-card-text>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize white elevation-0" text @click="closeDialog">Close</v-btn>
          <v-btn v-if="showForm" class="primary px-3 text-capitalize" :loading="nextLoading" dark @click="handleSubmit">Next</v-btn>
          <v-btn v-else class="primary px-3 text-capitalize" :loading="formLoading" dark @click="savePriceList">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Snackbar :toaster.sync="toaster"></Snackbar>
  </div>
</template>

<script>
import Snackbar from '@/components/Snackbar'
import { Constants } from '@/Constants'
import Vue from 'vue'
import draggable from 'vuedraggable'
const form = { priceListName: '', productIds: [] }

export default {
  name: 'PriceListForm',
  components: { Snackbar, draggable },
  props: ['dialog', 'id'],
  data () {
    return {
      form: { ...form },
      // categories: [],
      formLoading: false,
      toaster: { enable: false },
      productObj: {},
      categoryObj: {},
      isSelectedAll: false,
      showForm: true,
      selectedProducts: [],
      nextLoading: false
    }
  },
  computed: {
    modal: {
      get () { return this.dialog },
      set (val) { this.$emit('update:dialog', val) }
    },
    categories () { return this.$store.state.productCategories }
  },
  methods: {
    onSelectCategory ({ categoryId, childCategory }) {
      const flag = !this.categoryObj[categoryId] // toggle
      Vue.set(this.categoryObj, categoryId, flag)
      childCategory.forEach(p => {
        if (!p.isCategory) Vue.set(this.productObj, p.productId, flag)
      })
      this.checkSelectAll()
    },
    onSelectProduct ({ categoryId, childCategory }, productId) {
      if (productId) Vue.set(this.productObj, productId, !this.productObj[productId]) // toggle
      const flag = childCategory.every(p => (this.productObj[p.productId] || p.isCategory))
      Vue.set(this.categoryObj, categoryId, flag)
      this.checkSelectAll()
    },
    setCategoryAndProductObj (productIds) {
      // Set productObj & categoryObj
      productIds.forEach(id => { Vue.set(this.productObj, id, true) })
      this.categories.forEach(c => this.onSelectProduct(c))
    },
    setPriceList (row) {
      this.form = { priceListName: row.priceListName, productIds: [...row.productIds] }
      this.setCategoryAndProductObj([...row.productIds])
    },
    onSelectAll () {
      this.categories.forEach(c => {
        Vue.set(this.categoryObj, c.categoryId, this.isSelectedAll)
        c.childCategory.forEach(p => {
          if (!p.isCategory) Vue.set(this.productObj, p.productId, this.isSelectedAll)
        })
      })
    },
    checkSelectAll () {
      this.isSelectedAll = this.categories.every(c => this.categoryObj[c.categoryId])
    },
    handleSubmit () {
      const productIds = Object.keys(this.productObj).filter(key => (key && this.productObj[key]))
      // this.form.productIds = Object.keys(this.productObj).filter(key => (key && this.productObj[key]))
      if (this.$refs.form.validate() && productIds.length) this.onNext(productIds)
      // if (this.$refs.form.validate() && this.form.productIds.length) this.savePriceList()
      else if (this.$refs.form.validate() && !productIds.length) {
        this.toaster = { enable: true, color: 'red', message: 'Please select at least one product' }
      }
    },
    onNext (productIds) {
      this.nextLoading = true
      const productsMap = {}
      this.categories.forEach(c => {
        c.childCategory.forEach(p => {
          if (!p.isCategory) productsMap[p.productId] = { id: p.productId, name: p.productName }
        })
      })
      this.selectedProducts = productIds.map(id => productsMap[id])
      this.nextLoading = false
      this.showForm = false
    },
    async savePriceList () {
      this.formLoading = true
      this.form.productIds = this.selectedProducts.map(p => p.id)
      let data = {}
      if (this.id) {
        data = { ...Constants.updatePriceList, parameters: [localStorage.session, { ...this.form, priceListId: this.id }] }
      } else data = { ...Constants.addPriceList, parameters: [localStorage.session, this.form] }
      // eslint-disable-next-line no-undef
      axios.post(Constants.api_url, data).then(res => {
        if (res.data.flag) {
          this.toaster = { enable: true, color: 'green', message: `Price List ${this.id ? 'updated' : 'added'} successfully` }
          this.$emit('fetchPriceList', true)
          this.closeDialog()
        }
        this.formLoading = false
      }).catch(e => console.log('savePriceList', e))
    },
    closeDialog () {
      if (this.$refs.form) this.$refs.form.resetValidation()
      this.form = { ...form, productIds: [] }
      // this.categories = []
      this.categoryObj = {}
      this.productObj = {}
      this.isSelectedAll = false
      this.showForm = true
      this.selectedProducts = []
      this.$emit('update:dialog', false)
      this.$emit('update:id', null)
    }
  }
  // created () {
  //   this.categories = this.$store.state.productCategories
  // }
}
</script>

<style scoped>
.close-btn{
  position: absolute;
  right: 20px;
}
.p-row{
  border-bottom: 1px solid #dedede;
}
.p-row label, .p-row input{
  cursor: pointer;
}
.select-all{
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.product-row{
  cursor: pointer;
  padding: 6px 4px;
  border-bottom: 1px solid #dedede;
}
.drag-icon{
  visibility: hidden;
  cursor: pointer;
}
.product-row:hover .drag-icon  {
  visibility: visible;
}
.product-row:hover  {
  background: #f0f0f0;
}
</style>
