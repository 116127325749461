export const Constants = {
  api_url: 'https://client.meracrm.com/index.php',
  getProducts: {
    methodName: 'retrieveHirarchyProducts',
    serviceName: 'com.avinashi.meraCRM.modules.products.ProductService'
  },
  getPriceList: {
    methodName: 'retrievePriceLists',
    serviceName: 'com.avinashi.meraCRM.modules.priceList.PriceListService'
  },
  addPriceList: {
    methodName: 'addPriceList',
    serviceName: 'com.avinashi.meraCRM.modules.priceList.PriceListService'
  },
  updatePriceList: {
    methodName: 'updatePriceList',
    serviceName: 'com.avinashi.meraCRM.modules.priceList.PriceListService'
  },
  removePriceList: {
    methodName: 'removePriceList',
    serviceName: 'com.avinashi.meraCRM.modules.priceList.PriceListService'
  },
  downloadPriceList: {
    methodName: 'downloadPriceListPdf',
    serviceName: 'com.avinashi.meraCRM.modules.priceList.PriceListService'
  },
  updatePrice: {
    methodName: 'updateProductPrice',
    serviceName: 'com.avinashi.meraCRM.modules.priceList.PriceListService'
  },
  getPriceHistory: {
    methodName: 'getProductPriceHistory',
    serviceName: 'com.avinashi.meraCRM.modules.priceList.PriceListService'
  }
}
export const priceOperation = {
  add: 1,
  subtract: 2
}
export const priceType = {
  percentage: 1,
  amount: 2
}
