<template>
    <div>
        <v-dialog @keydown.esc="$emit('close')" max-width="400" persistent v-model="confirmDialog">
            <v-card>
                <v-card-title style="word-break: break-word" v-html="confirmationMessage"></v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click.native="$emit('close')" class="error--text elevation-0" text>Cancel</v-btn>
                    <v-btn @click.once="$emit('confirm')" class="primary--text elevation-0" text>Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
  name: 'ConfirmationDialog',
  props: ['confirmationMessage'],
  data: function () {
    return {
      confirmDialog: true
    }
  }
}
</script>
