<template>
  <div id="toaster">
    <v-snackbar :color="toast.color" v-model="toast.enable" :timeout="3000" bottom>
      <span v-html="toast.message?toast.message:'Something went Wrong'"></span>
      <!--<v-btn icon @click="toaster.enable = false">
          <v-icon>close</v-icon>
      </v-btn>-->
    </v-snackbar>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Snackbar',
  props: ['toaster'],
  computed: {
    toast: {
      get () { return this.toaster || {} },
      set (val) { this.$emit('update:toaster', val) }
    }
  }
}
</script>

<style scoped>

</style>
