<template>
  <div>

    <v-dialog v-model="modal" persistent>
      <v-card>
        <v-card-title class="text-h5 pl-6 font-weight-regular">Download Price List</v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="form">
            <v-row>
              <v-col cols="auto">
                <label class="text-uppercase grey--text font-weight-bold">Price List Name</label>
                <div style="font-size: 20px;" class="mt-2">{{priceList&&priceList.priceListName}}</div>
              </v-col>
              <v-col cols="auto" class="ml-6">
                <label class="text-uppercase grey--text font-weight-bold">Date</label>
                <v-menu :close-on-content-click="false" :nudge-right="40" min-width="290px" offset-y
                        transition="scale-transition" v-model="dateMenu">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="mt-1" clearable outlined prepend-inner-icon="mdi-calendar-blank-outline"
                                  readonly v-bind="attrs" v-model="dateFormat" v-on="on" :rules="[v => !!v || 'Date required']"/>
                  </template>
                  <v-date-picker @input="dateMenu = false" v-model="date"/>
                </v-menu>
              </v-col>
              <v-col cols="auto" class="ml-10">
                <v-checkbox label="Include Product Price" v-model="includeProductPrice"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize white elevation-0" text @click="closeDialog">Close</v-btn>
          <v-btn class="primary px-3 text-capitalize" :loading="downloadLoading" dark @click="handleSubmit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Snackbar :toaster.sync="toaster"></Snackbar>
  </div>
</template>

<script>
import Snackbar from '@/components/Snackbar'
import { Constants } from '@/Constants'
import moment from 'moment'

export default {
  name: 'DownloadPriceList',
  components: { Snackbar },
  props: ['dialog'],
  data () {
    return {
      downloadLoading: false,
      toaster: { enable: false },
      date: moment().format('YYYY-MM-DD'),
      dateMenu: false,
      priceList: null,
      includeProductPrice: true
    }
  },
  computed: {
    modal: {
      get () { return this.dialog },
      set (val) { this.$emit('update:dialog', val) }
    },
    dateFormat: {
      get () { return this.date ? moment(this.date, 'YYYY-MM-DD').format('DD-MM-YYYY') : null },
      set () { this.date = null }
    }
  },
  methods: {
    setPriceList (row) {
      this.priceList = row
    },
    handleSubmit () {
      if (this.$refs.form.validate()) this.downloadPriceList()
    },
    async downloadPriceList () {
      this.downloadLoading = true
      const data = { ...Constants.downloadPriceList, parameters: [localStorage.session, this.priceList.priceListId, moment(this.date).format('DD-MMM-YYYY'), this.includeProductPrice] }
      // eslint-disable-next-line no-undef
      axios.post(Constants.api_url, data).then(res => {
        if (res.data.flag) {
          this.closeDialog()
          window.open(res.data.data, '_blank')
        }
        this.downloadLoading = false
      }).catch(e => console.log('downloadPriceList', e))
    },
    closeDialog () {
      this.$refs.form.resetValidation()
      this.date = moment().format('YYYY-MM-DD')
      this.includeProductPrice = true
      this.$emit('update:dialog', false)
      this.priceList = null
    }
  }
}
</script>

<style scoped>

</style>
